import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom"

import { useLayoutEffect } from "react"
import { useTranslation } from "react-i18next"

import Footer from "./components/footer"
import Kvkk from "./components/kvkk"
import Modal from "./components/modal"
import Preloader from "./components/preloader"
import ScrollToTop from "./components/scrollToTop"
import Header from "./hocs/header"
import BoardMembers from "./pages/boardMembers"
import Contact from "./pages/contact"
import Executives from "./pages/executives"
import Home from "./pages/home"
import { getLang } from "./utils"

function App() {
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const background = location.state && location.state.background

  useLayoutEffect(() => {
    getLang().then((lang) => {
      if (!lang) return

      if (lang === "TR") {
        i18n.changeLanguage("TR")
      } else {
        i18n.changeLanguage("EN")
      }
    })
  }, [])

  useLayoutEffect(() => {
    if (
      location.pathname === "/home" ||
      location.pathname === "/executives" ||
      location.pathname === "/board-members" ||
      location.pathname === "/contact" ||
      location.pathname === "/pdpa"
    ) {
      if (i18n.language === "TR") {
        i18n.changeLanguage("EN")
      }
    } else if (
      location.pathname === "/anasayfa" ||
      location.pathname === "/yoneticiler" ||
      location.pathname === "/yonetim-kurulu" ||
      location.pathname === "/iletisim" ||
      location.pathname === "/kvkk"
    ) {
      if (i18n.language === "EN") {
        i18n.changeLanguage("TR")
      }
    }
  }, [])

  return (
    <>
      <ScrollToTop />
      <Header />

      <Routes location={background || location}>
        <Route exact path={""} element={<Navigate to={t("routes.home")} />} />

        <Route exact path={"/home"} element={<Home />} />
        <Route exact path={"/anasayfa"} element={<Home />} />

        <Route exact path={"/executives"} element={<Executives />} />
        <Route exact path={"/yoneticiler"} element={<Executives />} />

        <Route exact path={"/board-members"} element={<BoardMembers />} />
        <Route exact path={"/yonetim-kurulu"} element={<BoardMembers />} />

        <Route exact path={"/contact"} element={<Contact />} />
        <Route exact path={"/iletisim"} element={<Contact />} />
      </Routes>

      <Footer />
      {/* <MagnetCursor></MagnetCursor> */}
      <Preloader></Preloader>

      {background && (
        <Routes>
          <Route
            exact
            path={"/kvkk"}
            element={
              <Modal>
                <Kvkk />
              </Modal>
            }
          />
          <Route
            exact
            path={"/pdpa"}
            element={
              <Modal>
                <Kvkk />
              </Modal>
            }
          />
        </Routes>
      )}
      <Outlet />
    </>
  )
}

export default App
