import React from "react";

export default function IconClose({ fill = "none", stroke = "#ffffff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 16.461 16.461"
    >
      <g
        id="Group_114"
        data-name="Group 114"
        transform="translate(-307.77 -38.769)"
      >
        <path
          id="Path_4671"
          data-name="Path 4671"
          d="M0,0H22.279"
          transform="translate(308.123 39.123) rotate(45)"
          fill={fill}
          stroke={stroke}
          strokeWidth="1"
        />
        <path
          id="Path_4670"
          data-name="Path 4670"
          d="M0,0H22.279"
          transform="translate(308.123 54.877) rotate(-45)"
          fill={fill}
          stroke={stroke}
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
