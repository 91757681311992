import React, { useEffect, useRef } from "react";
import s from "../assets/scss/kvkk.module.scss";

import { gsap } from "../utils/gsap";

export default function Kvkk() {
  const contentRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      contentRef.current,
      { autoAlpha: 0, yPercent: 1 },
      {
        autoAlpha: 1,
        yPercent: 0,
        duration: 0.3,
        delay: 0.3,
      }
    );
  }, []);

  return (
    <div className={s.content} ref={contentRef}>
      <h1 className={s.titleL}>
        GİZLİLİK POLİTİKASI VE KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA
        VERİLERİNİZİN İŞLENMESİYLE İLGİLİ AYDINLATMA BEYANI
      </h1>
      <p className={s.text}>
        <strong>
          WAM Gayrimenkul ve Girişim Sermayesi Portföy Yönetimi A.Ş. (“Şirket”)
        </strong>{" "}
        olarak kişisel verilerinizin güvenliğinin sağlanmasına son derece önem
        vermekteyiz. Bu kapsamda 6698 Sayılı “Kişisel Verilerin Korunması
        Kanunu”nu <strong>(“KVK Kanunu”)</strong> uyarınca kişisel verilerinizin
        işlenmesi ve üçüncü kişilere aktarımı süreçlerinde, kişisel
        verilerinizin hukuka aykırı olarak işlenmesini ve erişilmesini önlemek
        ve muhafazasını sağlamak için uygun güvenlik düzeyini sağlamak için
        gerekli tedbirleri almaktayız. Bu sebeple işbu beyan, Şirketimizin
        faaliyetleri kapsamında kişisel verileri elde edilen ilgili kişileri
        bilgilendirmek için hazırlanmıştır.
      </p>
      <p className={s.text}>
        Şirketimiz tarafından hazırlanan işbu Aydınlatma Beyanı, 21/07/2022
        tarihinde yürürlüğe konulmuştur. Şirketimiz tarafından düzenlenen bu
        Beyan’ın tamamının veya belirli maddelerinin yenilenmesi durumunda
        Beyan’ın yürürlük tarihi ve versiyonu güncellenecektir. Beyan
        Şirketimizin internet sitesinde <strong>www.wamportfoy.com</strong>{" "}
        yayımlanır ve ilgili kişilerin erişimine sunulur.
      </p>
      <h2 className={s.titleM}>1. VERİLERİNİZİ İŞLEYEN KİŞİ:</h2>
      <p className={s.text}>
        Şirket olarak veri sorumlusu sıfatıyla, kişisel verilerinizin işleme
        amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin
        kurulmasından ve yönetilmesinden sorumlu tüzel kişiyiz. Kişisel
        verilerinizin işlenmesine ilişkin açık rızanızı vermenizle veya açık
        rızanın gerekmediği durumlarda yapacağınız bilgilendirmeyle birlikte
        Şirketimiz kişisel verilerinizi veri güvenliğini sağlayarak işlemeye
        başlayacaktır. Kişisel verilerinizi işlerken bir veya birden fazla veri
        işleyeni yetkilendirerek gerekli güvenlik düzeyini sağlamak suretiyle
        verilerinizi onlara da işletebiliriz.
      </p>
      <h2 className={s.titleM}>
        2. KİŞİSEL VERİLERİNİZİN İŞLENMESİNİN HUKUKİ DAYANAĞI, HANGİ YÖNTEMLERLE
        ELDE EDİLEBİLECEĞİ VE HANGİ VERİLERİNİZİN İŞLENECEĞİ:
      </h2>
      <p className={s.text}>
        Sermaye Piyasası Kanunu ve diğer mevzuat gereği Şirketimizin faaliyet
        konularına ilişkin hizmetlerin sunulabilmesi ve bu hizmetlerin
        kalitesini artırabilmekle beraber diğer faaliyetlerini yerine
        getirebilmek ile bilgilendirme yükümlülüklerine uymak; her türlü ürün ve
        hizmetlerde kullanmak; işlem sahibinin bilgilerini tespit ve teyit
        etmek; elektronik (internet) veya yazılı ortamda gerçekleştirilecek
        işleme dayanak olacak tüm kayıt ve belgeleri düzenlemek; BDDK, TCMB ve
        diğer resmi kurumlarca öngörülen bilgi saklama, raporlama, bilgilendirme
        yükümlülüklerine uymak; talep edilen diğer portföy yönetimi hizmetlerini
        sunabilmek ve akdettiğiniz sözleşmenin gereğini yerine getirmek;
        sözleşme süreçlerini yürütmek; müşteri hesap açılış süreçlerini
        yürütmek; sistem tanımlanmasının kontrolünü sağlamak; fonlara ilişkin
        performans bildirimlerini yapmak; özel portföy yönetim işlemlerini
        portföy yönetim sözleşmesi ve mevzuata uygunluk kapsamında kontrol
        etmek; iç kontrol raporlama süreçlerini yürütmek; müşteri şikayet ve
        bildirimlerini takip etmek; MASAK Kontrolü sürecini yürütmek; periyodik
        müşteri raporlarının kontrol sürecinin denetimini sağlamak; iletişim
        faaliyetlerini yürütmek; tedarikçiler ile sözleşme sürecini yürütmek;
        faturalama sürecini yürütmek; hukuk işlerini takip etmek ve yürütmek
        amaçlarıyla <strong>EK-1’de yer alan kişisel verileriniz</strong>{" "}
        işlenmektedir.
      </p>
      <h2 className={s.titleM}>3. KİŞİSEL VERİLERİNİZİN PAYLAŞILMASI:</h2>
      <p className={s.text}>
        Yukarıda sayılan amaçlarla işlenen kişisel verileriniz; Sermaye Piyasası
        mevzuatı ve diğer mevzuat hükümlerinin izin verdiği/gerekli kıldığı kişi
        veya kuruluşlar ve bunlarla sınırlı olmamak üzere BDDK, TCMB gibi kamu
        tüzel kişileriyle; ana hissedarımız, doğrudan/dolaylı, yurt içi/yurt
        dışı iştiraki olduğumuz grup ve grubun diğer iştirakleriyle; sermaye
        piyasası faaliyetlerimizi yürütmek üzere işbirliği yaptığımız yatırım
        kuruluşları ve finansal kuruluşlarla, hizmet alınan yada hizmet alınması
        planlanan gerçek ve/veya tüzel kişiler ile resmi/idari makamlarla
        paylaşılabilecektir.
      </p>
      <h2 className={s.titleM}>4. KİŞİSEL VERİLERİNİZİN İMHA EDİLMESİ:</h2>
      <p className={s.text}>
        Şirketimiz, işlediği kişisel verileri mevzuatla belirlenen sürelerde
        saklamakta olup mevzuatta ayrıca süre belirlenmemiş olması halinde;
        kişisel veriler şirketimizin o veriyi işlerken sunduğu hizmetlerle bağlı
        olarak Şirketimizin uygulamaları ve ticari yaşamın teamülleri uyarınca
        işlenmesini gerektiren süre kadar ve bu süreden sonra sadece olası
        hukuki uyuşmazlıklarda delil teşkil etmesi amacıyla uygulamada
        gerekliliği ortaya konulan süreler boyunca saklamaktadır. Belirtilen
        sürelerin sona ermesinden sonra KVK Kanunu’nun 7. maddesi uyarınca söz
        konusu kişisel veriler ilk imha tarihinde silinmekte, yok edilmekte veya
        anonim hale getirilmektedir.
      </p>
      <h2 className={s.titleM}>5. KİŞİSEL VERİLERİNİZİN SAKLANMA KOŞULLARI</h2>
      <p className={s.text}>
        Şirketimiz KVK Kanunu’na uygun olarak kişisel verilerin hukuka aykırı
        biçimde erişilmesini ve işlenmesini önlemek ile kişisel verilerin
        muhafazasını sağlamak amacıyla uygun güvenlik düzeyini temin etmeye
        yönelik gerekli her türlü teknik ve idari tedbirleri almaktadır. Ayrıca
        Şirketimiz Sermaye Piyasası Kanunu ve ilgili diğer mevzuat hükümlerinde
        belirtilen sorumluluklarını karşılayacak şekilde müşterilerini ve
        müşteri verilerini siber tehditlere karşı etkin bir şekilde korumak
        amacıyla en gelişmiş güvenlik teknolojilerini kullanmaktadır. Şirketimiz
        nezdinde saklanan müşteri kişisel verilerine erişim sadece tanımlı
        süreçlere göre yetkilendirilmiş personel tarafından
        gerçekleştirilebilmektedir. Hassas ve kritik verilere erişim için
        denetim izleri oluşturulmaktadır. İlgili kanun ve yönetmelikler
        kapsamında zorunlu tutulan risk değerlendirmeleri ile alınan önlemler
        sürekli değerlendirilmektedir. Uygulamaya alınan önlemler minimum olarak
        mevzuat hükümleri kapsamında tanımlanmış test çalışmaları ile
        değerlendirilmektedir. Böylece Şirketimiz nezdinde saklanan hassas ve
        kritik verilerin güvenliği makul bir şekilde sağlanmaktadır.
      </p>
      <h2 className={s.titleM}>
        6. KİŞİSEL VERİLERİNİZİN İŞLENMESİYLE İLGİLİ HAKLARINIZ:
      </h2>
      <p className={s.text}>
        <strong>
          KVK Kanunu’nun 11. maddesi çerçevesinde şirketimize başvurarak aşağıda
          yer alan taleplerinizi bize iletebilirsiniz;
        </strong>
      </p>
      <ol className={s.latinList}>
        <li>
          <p className={s.text}>
            Kişisel verilerinizin işlenip işlenmediğini öğrenme, işlenmişse buna
            ilişkin bilgi talep etme,
          </p>
        </li>

        <li>
          <p className={s.text}>
            Kişisel verilerinizin işlenme amacını ve bunların amacına uygun
            kullanılıp kullanılmadığını öğrenme,
          </p>
        </li>

        <li>
          <p className={s.text}>
            Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı
            üçüncü kişileri öğrenme,
          </p>
        </li>

        <li>
          <p className={s.text}>
            Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
            düzeltilmesini isteme,
          </p>
        </li>

        <li>
          <p className={s.text}>
            KVK Kanunu’nun 7. maddesi kapsamında kişisel verilerinizin
            işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel
            verilerinizin silinmesini, yok edilmesini ya da anonim hâle
            getirilmesini talep etme,
          </p>
        </li>

        <li>
          <p className={s.text}>
            (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel
            verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
          </p>
        </li>

        <li>
          <p className={s.text}>
            İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
            edilmesi suretiyle aleyhinize bir sonucun doğmasına itiraz etme,
          </p>
        </li>

        <li>
          <p className={s.text}>
            Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle
            zarara uğraması hâlinde zararın giderilmesini talep etme.
          </p>
        </li>
      </ol>
      <p className={s.text}>
        Kişisel veri sahipleri yukarıda belirtilen haklarına ilişkin taleplerini{" "}
        <strong>www.wamportfoy.com</strong> web adresinde bulunan formu eksiksiz
        doldurarak ve ıslak imza ile imzalayarak{" "}
        <strong>
          “Teşvikiye Mah. Teşvikiye Cad. AVM Blok No:12 İç Kapı No:108
          Şişli-İstanbul”
        </strong>{" "}
        adresine iadeli taahhütlü mektupla göndererek şirketimize
        iletebileceklerdir. Şirketimize iletilen usulüne uygun talepler en geç
        otuz gün içinde sonuçlandırılacaktır. Söz konusu taleplerin
        sonuçlandırılmasının ayrıca bir maliyeti gerektirmesi halinde,
        şirketimiz tarafından başvuru sahibinden Kişisel Verileri Koruma Kurulu
        tarafından belirlenen tarifedeki ücret alınacaktır.
      </p>
      <p className={s.text}>
        Şirketimiz, başvuruda bulunan kişinin kişisel veri sahibi olup
        olmadığını tespit etmek adına ilgili kişiden bilgi talep edebilir,
        başvuruda belirtilen hususları netleştirmek adına, kişisel veri sahibine
        başvurusu ile ilgili soru yöneltebilir.
      </p>
      <p className={s.text}>
        <strong>
          {" "}
          Şirketimiz aşağıda yer alan hallerde başvuruda bulunan kişinin
          başvurusunu, gerekçesini açıklayarak reddedebilir:
        </strong>
      </p>
      <ul className={s.bulletList}>
        <li>
          <p className={s.text}>
            Kişisel verilerin resmi istatistik ile anonim hâle getirilmek
            suretiyle araştırma, planlama ve istatistik gibi amaçlarla
            işlenmesi.
          </p>
        </li>

        <li>
          <p className={s.text}>
            Kişisel verilerin millî savunmayı, millî güvenliği, kamu
            güvenliğini, kamu düzenini, ekonomik güvenliği, özel hayatın
            gizliliğini veya kişilik haklarını ihlal etmemek ya da suç teşkil
            etmemek kaydıyla, sanat, tarih, edebiyat veya bilimsel amaçlarla ya
            da ifade özgürlüğü kapsamında işlenmesi.
          </p>
        </li>

        <li>
          <p className={s.text}>
            Kişisel verilerin millî savunmayı, millî güvenliği, kamu
            güvenliğini, kamu düzenini veya ekonomik güvenliği sağlamaya yönelik
            olarak kanunla görev ve yetki verilmiş kamu kurum ve kuruluşları
            tarafından yürütülen önleyici, koruyucu ve istihbari faaliyetler
            kapsamında işlenmesi.
          </p>
        </li>

        <li>
          <p className={s.text}>
            Kişisel verilerin soruşturma, kovuşturma, yargılama veya infaz
            işlemlerine ilişkin olarak yargı makamları veya infaz mercileri
            tarafından işlenmesi.
          </p>
        </li>

        <li>
          <p className={s.text}>
            Kişisel veri işlemenin suç işlenmesinin önlenmesi veya suç
            soruşturması için gerekli olması.
          </p>
        </li>

        <li>
          <p className={s.text}>
            Kişisel veri sahibi tarafından kendisi tarafından alenileştirilmiş
            kişisel verilerin işlenmesi.
          </p>
        </li>
        <li>
          <p className={s.text}>
            Kişisel veri işlemenin kanunun verdiği yetkiye dayanılarak görevli
            ve yetkili kamu kurum ve kuruluşları ile kamu kurumu niteliğindeki
            meslek kuruluşlarınca, denetleme veya düzenleme görevlerinin
            yürütülmesi ile disiplin soruşturma veya kovuşturması için gerekli
            olması.
          </p>
        </li>
        <li>
          <p className={s.text}>
            Kişisel veri işlemenin bütçe,vergi ve mali konulara ilişkin olarak
            Devletin ekonomik ve mali çıkarlarının korunması için gerekli
            olması.
          </p>
        </li>
        <li>
          <p className={s.text}>
            Kişisel veri sahibinin talebinin diğer kişilerin hak ve
            özgürlüklerini engelleme ihtimali olması
          </p>
        </li>
        <li>
          <p className={s.text}>
            Orantısız çaba gerektiren taleplerde bulunulmuş olması.
          </p>
        </li>
        <li>
          <p className={s.text}>
            Talep edilen bilginin kamuya açık bir bilgi olması.
          </p>
        </li>
      </ul>
      <p className={s.text}>
        Kişisel veri sahibi KVK Kanunu’nun 14. maddesi gereğince başvurunun
        reddedilmesi, verilen cevabın yetersiz bulunması veya süresinde
        başvuruya cevap verilmemesi hâllerinde; Şirketimizin cevabını öğrendiği
        tarihten itibaren otuz ve herhâlde başvuru tarihinden itibaren altmış
        gün içinde Kurul’a şikâyette bulunabilir.
      </p>
      <p className={s.titleM}>
        <strong>EK-1: Kişisel Veri Kategorileri</strong>
      </p>
      <p className={s.titleS}>
        <strong>Kimlik bilgisi</strong>
      </p>
      <p className={s.text}>
        Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık
        olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin
        bir parçası olarak otomatik olmayan şekilde işlenen; Ehliyet, Nüfus
        Cüzdanı, İkametgâh, Pasaport, Avukatlık Kimliği, Evlilik Cüzdanı gibi
        dokümanlarda yer alan tüm bilgiler
      </p>

      <p className={s.titleS}>
        <strong>İletişim bilgisi</strong>
      </p>
      <p className={s.text}>
        Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık
        olan; kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin
        bir parçası olarak otomatik olmayan şekilde işlenen; telefon numarası,
        adres, email gibi bilgiler
      </p>

      <p className={s.titleS}>
        <strong>Müşteri bilgisi</strong>
      </p>
      <p className={s.text}>
        Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık
        olan, kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin
        bir parçası olarak otomatik olmayan şekilde işlenen; ticari
        faaliyetlerimiz ve bu çerçevede iş birimlerimizin yürüttüğü operasyonlar
        neticesinde müşterinin yetkilisi veya çalışanı ilgili kişi hakkında elde
        edilen ve üretilen bilgiler
      </p>

      <p className={s.titleS}>
        <strong>Müşteri işlem bilgisi</strong>
      </p>
      <p className={s.text}>
        Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık
        olan ve veri kayıt sistemi içerisinde yer alan; ürün ve hizmetlerimizin
        kullanımına yönelik kayıtlar ile müşterinin ürün ve hizmetleri kullanımı
        için gerekli olan talimatları ve talepleri gibi bilgiler
      </p>

      <p className={s.titleS}>
        <strong>İşlem güvenliği bilgisi</strong>
      </p>
      <p className={s.text}>
        Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık
        olan ve veri kayıt sistemi içerisinde yer alan; ticari faaliyetlerimizi
        yürütürken teknik, idari, hukuki ve ticari yükümlülüklerin
        gerçekleştirilmesinin temini amaçları için işlenen kişisel verileriniz
      </p>

      <p className={s.titleS}>
        <strong>Risk yönetimi bilgisi</strong>
      </p>
      <p className={s.text}>
        Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık
        olan ve veri kayıt sistemi içerisinde yer alan; ticari, teknik ve idari
        risklerimizi yönetebilmemiz için bu alanlarda genel kabul görmüş hukuki,
        ticari teamül ve dürüstlük kuralına uygun olarak kullanılan yöntemler
        vasıtasıyla işlenilen kişisel veriler
      </p>

      <p className={s.titleS}>
        <strong>Finansal bilgi</strong>
      </p>
      <p className={s.text}>
        Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık
        olan, kısmen veya tamamen otomatik şekilde veya veri kayıt sisteminin
        bir parçası olarak otomatik olmayan şekilde işlenen; Müşterinin her
        türlü finansal sonucu gösteren bilgi, belge ve kayıtlara ilişkin
        müşterinin yetkilisi veya çalışanının kişisel verileri
      </p>
      <p className={s.titleS}>
        <strong>Demografik bilgi</strong>
      </p>
      <p className={s.text}>
        Fotoğraf, meslek, eğitim seviyesi, gelir seviyesi ve benzeri profil
        bilgileri
      </p>
      <p className={s.titleS}>
        <strong>İş, Profesyonel Yaşam ve Eğitim bilgisi</strong>
      </p>
      <p className={s.text}>Unvan, meslek, mezun olunan okul bilgisi</p>
    </div>
  );
}
