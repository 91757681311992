import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import smoothscroll from "smoothscroll-polyfill";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    if (pathname !== t("routes.kvkk") && pathname !== "/") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [pathname]);

  return null;
}
