import React from "react";
import HeaderTouchscreen from "../components/headerTouchscreen";
import HeaderDesktop from "../components/headerDesktop";

import useWindowSize from "../hooks/useWindowSize";

export default function Header() {
  const size = useWindowSize();

  return <>{size.width <= 1024 ? <HeaderTouchscreen /> : <HeaderDesktop />}</>;
}
