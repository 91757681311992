import React, { useLayoutEffect, useRef } from "react";
import s from "../assets/scss/modal.module.scss";

import { useNavigate } from "react-router-dom";
import { ReactComponent as Close } from "../assets/icon/close.svg";
import { gsap } from "../utils/gsap";

export default function Modal({ children }) {
  const modalRef = useRef(null);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    disableScrolling();

    gsap.fromTo(
      modalRef.current,
      { autoAlpha: 0 },
      {
        autoAlpha: 1,
        duration: 0.2,
      }
    );

    return () => {};
  }, []);

  const closeModal = () => {
    gsap.to(modalRef.current, {
      autoAlpha: 0,
      onComplete: () => {
        enableScrolling();
        navigate(-1);
      },
    });
  };

  function disableScrolling() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }

  function enableScrolling() {
    window.onscroll = function () {};
  }

  return (
    <div className={s.modal} ref={modalRef}>
      <Close className={s.close} onClick={closeModal} />
      {children}
    </div>
  );
}
