import React, { useEffect, useLayoutEffect, useRef } from "react";
import s from "../assets/scss/boardMembers.module.scss";

// import arrow from "../assets/icon/arrow-large.svg";
import sphere from "../assets/img/sphere.png";
import useHeaderStore from "../stores/themeStore";

import cx from "classnames";
// import useExecPanelStore from "../stores/executivePanelStore";
// import ExecutivePanel from "../components/executivePanel";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Board() {
  const boardMembersRef = useRef(null);
  const { t, i18n } = useTranslation();

  const setHeaderDark = useHeaderStore((state) => state.setDarkTheme);

  useLayoutEffect(() => {
    setHeaderDark();
  }, []);

  const items = [
    {
      name: t("executiveInfo.e1.name"),
      role: t("executiveInfo.e1.role"),
      inProgress: true,
    },
    {
      name: t("executiveInfo.e2.name"),
      role: t("executiveInfo.e2.role"),
      inProgress: true,
    },
    {
      name: t("executiveInfo.e3.name"),
      role: t("executiveInfo.e3.role"),
      inProgress: true,
    },
    {
      name: t("executiveInfo.e4.name"),
      role: t("executiveInfo.e4.role"),
      inProgress: true,
    },
  ];

  let navigate = useNavigate();
  useEffect(() => {
    if (i18n.language === "TR") {
      navigate("/yonetim-kurulu");
    } else {
      navigate("/board-members");
    }
  }, [i18n.language]);

  // const togglePanel = useExecPanelStore((state) => state.toggle);
  // const setExec = useExecPanelStore((state) => state.setCurrentExec);
  // const currentExec = useExecPanelStore((state) => state.currentExec);

  // const handleSelect = (selected) => {
  //   setExec(selected);
  //   togglePanel();
  // };

  return (
    <main className={s.boardMembersWrapper} ref={boardMembersRef}>
      <section className={s.boardMembers}>
        <div className={s.textBox}>
          <h3 className={s.title}>
            {t("boardMembers.title.part1")} <br />{" "}
            {t("boardMembers.title.part2")}
          </h3>
          <p className={s.text}>{t("boardMembers.text")}</p>
        </div>

        <div className={s.memberList}>
          {items.map((item, i) => {
            return (
              <div
                className={cx(s.member, { [s.inProgress]: item.inProgress })}
                key={i}
                /*                  onClick={() => handleSelect(i)}
                 */
              >
                <h5 className={s.title}>{item.name}</h5>
                <small className={s.role}>{item.role}</small>
                {/* <img
                  className={cx(s.arrow, { [s.inProgress]: item.inProgress })}
                  src={arrow}
                  alt="Arrow"
                /> */}
              </div>
            );
          })}
        </div>
        <img className={s.sphere} src={sphere} alt="Sphere" />
      </section>
      {/* <ExecutivePanel currentExec={currentExec}></ExecutivePanel> */}
    </main>
  );
}
