import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import s from "../assets/scss/headerDesktop.module.scss"

import cx from "classnames"
import arrow from "../assets/icon/arrow.svg"
import wamLogoGray from "../assets/img/wam-logo-gray.svg"
import wamLogo from "../assets/img/wam-logo.svg"
import Arrow from "./iconArrow"

import anaSozlesme from "../assets/pdf/ana-sozlesme.pdf"
import bagimsizDenetci from "../assets/pdf/bagimsiz-denetci-raporu.pdf"
import faaliyetRaporu from "../assets/pdf/faaliyet-raporu.pdf"
import yetkiBelgesi from "../assets/pdf/spk-faaliyet-yetki-belgesi.pdf"
import bagimsizDenetci2023firstHalf from "../assets/pdf/wam-portfoy-yonetimi-bagimsiz-denetim-raporu-2023-ara-donem.pdf"
import bagimsizDenetci2023yearEnd from "../assets/pdf/wam-portfoy-yonetimi-bagimsiz-denetim-raporu-2023-yil-sonu.pdf"
import faaliyetRaporu2023firstHalf from "../assets/pdf/wam-portfoy-yonetimi-faaliyet-raporu-2023-ara-donem.pdf"
import faaliyetRaporu2023yearEnd from "../assets/pdf/wam-portfoy-yonetimi-faaliyet-raporu-2023-yil-sonu.pdf"

import useWindowSize from "../hooks/useWindowSize"
import useCursorStore from "../stores/cursorStore"
import useHeaderStore from "../stores/themeStore"

import { Link, useLocation } from "react-router-dom"
import AnimatedButton from "./animatedButton"
import LanguageDropdown from "./languageDropdown"

import { gsap } from "gsap"
import { useTranslation } from "react-i18next"
import usePreloaderStore from "../stores/preloaderStore"

export default function Header() {
  const location = useLocation()
  const { t } = useTranslation()
  const size = useWindowSize()
  const headerRef = useRef(null)
  const q = gsap.utils.selector(headerRef)
  const navMenuTL = useRef(null)
  const submenuItemsTL = useRef(null)
  const submenuRef = useRef(null)
  const headerSelector = gsap.utils.selector(headerRef)
  const [closeOverlayWidth, setCloseOverlayWidth] = useState(null)
  const [currentNavItem, setCurrentNavItem] = useState(null)
  const [submenuItems, setSubmenuItems] = useState([])
  const [currentSubmenu, setCurrentSubmenu] = useState(null)

  const scaleCursorUp = useCursorStore((state) => state.scaleUp)
  const scaleCursorDown = useCursorStore((state) => state.scaleDown)
  const isDarkTheme = useHeaderStore((state) => state.isDark)

  const navigation = {
    about: {
      sub: [
        // { title: "Şirket Profilimiz", url: "company-profile", pdf: null, inProgress: true },
        {
          title: t("header.link.about.submenu.boardMembers"),
          url: t("routes.boardMembers"),
          pdf: null,
          inProgress: false,
        },
        {
          title: t("header.link.about.submenu.executives"),
          url: t("routes.executives"),
          pdf: null,
          inProgress: false,
        },
        {
          title: t("header.link.about.submenu.license"),
          url: null,
          pdf: yetkiBelgesi,
          inProgress: false,
        },
      ],
    },
    financialInfo: {
      sub: [
        {
          title: t("header.link.financialInfo.submenu.mainContract.title"),
          type: "MAIN_CONTRACT",
          pdf: anaSozlesme,
          url: null,
          inProgress: false,
        },
        {
          title: t("header.link.financialInfo.submenu.files.title"),
          type: "FILES",
          url: null,
          pdf: null,
          inProgress: true,
        },
        {
          title: t("header.link.financialInfo.submenu.reports.title"),
          type: "REPORTS",
          url: null,
          pdf: null,
          inProgress: false,
          sub: [
            {
              title: t("header.link.financialInfo.submenu.reports.sub.s3.ui"),
              pdf: faaliyetRaporu2023yearEnd,
            },
            {
              title: t("header.link.financialInfo.submenu.reports.sub.s2.ui"),
              pdf: faaliyetRaporu2023firstHalf,
            },
            {
              title: t("header.link.financialInfo.submenu.reports.sub.s1.ui"),
              pdf: faaliyetRaporu,
            },
          ],
        },
        {
          title: t("header.link.financialInfo.submenu.audit.title"),
          type: "AUDIT",
          url: null,
          pdf: null,
          inProgress: false,
          sub: [
            {
              title: t("header.link.financialInfo.submenu.audit.sub.s3.ui"),
              pdf: bagimsizDenetci2023yearEnd,
            },
            {
              title: t("header.link.financialInfo.submenu.audit.sub.s2.ui"),
              pdf: bagimsizDenetci2023firstHalf,
            },
            {
              title: t("header.link.financialInfo.submenu.audit.sub.s1.ui"),
              pdf: bagimsizDenetci,
            },
          ],
        },
      ],
    },
  }

  // INIT
  useLayoutEffect(() => {
    gsap.set(q("[data-home-logo]"), {
      willChange: "transform",
      autoAlpha: 0,
    })

    const navMenu = headerSelector(".nav-menu")

    navMenuTL.current = gsap.timeline({ paused: true }).fromTo(
      navMenu,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.2,
      }
    )
  }, [])

  useEffect(() => {
    setCurrentNavItem(null)
    navMenuTL.current.reverse()
    // document.body.style.overflow = "auto";
    enableScrolling()
  }, [location])

  useEffect(() => {
    setCloseOverlayWidth(submenuRef.current.getBoundingClientRect().left - 100)
  }, [size.width, currentNavItem])

  useLayoutEffect(() => {
    const headerSelector = gsap.utils.selector(headerRef)
    const submenuItems = headerSelector(".submenu-item")

    submenuItemsTL.current = gsap.timeline({ paused: true }).fromTo(
      submenuItems,
      {
        autoAlpha: 0,
        xPercent: -3,
      },
      {
        autoAlpha: 1,
        xPercent: 0,
        stagger: 0.03,
      }
    )

    if (submenuItems) {
      submenuItemsTL.current.play()
    } else {
      submenuItemsTL.current.reverse()
    }
  }, [submenuItems])

  useEffect(() => {
    if (currentNavItem === "ABOUT") {
      setSubmenuItems(navigation.about.sub)
      navMenuTL.current.play()
      // document.body.style.overflow = "hidden";
      disableScrolling()
    } else if (currentNavItem === "FINANCIAL_INFO") {
      setSubmenuItems(navigation.financialInfo.sub)
      navMenuTL.current.play()
      // document.body.style.overflow = "hidden";
      disableScrolling()
    } else {
      setSubmenuItems(null)
      navMenuTL.current.reverse()
      // document.body.style.overflow = "auto";
      enableScrolling()
    }
  }, [currentNavItem])

  function disableScrolling() {
    var x = window.scrollX
    var y = window.scrollY
    window.onscroll = function () {
      window.scrollTo(x, y)
    }
  }

  function enableScrolling() {
    window.onscroll = function () {}
  }

  const isAnimationEnd = usePreloaderStore((state) => state.animationEnd)

  useEffect(() => {
    gsap.to(q("[data-home-logo]"), {
      autoAlpha: 1,
      delay: 2.5,
      duration: 1,
    })
  }, [isAnimationEnd])

  function handleSubmenuOpen(item) {
    scaleCursorUp()
    setCurrentSubmenu(item.type)
  }

  function handleSubmenuClose() {
    scaleCursorDown()
    setCurrentSubmenu(null)
  }

  return (
    <header className={cx(s.header, { [s.dark]: isDarkTheme })} ref={headerRef}>
      <div className={s.top} onMouseEnter={() => setCurrentNavItem(null)}>
        <LanguageDropdown color="#ffffff" />
      </div>

      <nav className={cx(s.nav, { [s.dark]: isDarkTheme })}>
        <div className={cx(s.imgWrapper, { [s.dark]: isDarkTheme })} onMouseEnter={() => setCurrentNavItem(null)}>
          <Link to={t("routes.home")} style={{ cursor: "pointer" }}>
            <img data-home-logo src={isDarkTheme ? wamLogoGray : wamLogo} alt="WAM Logo" />
          </Link>
        </div>

        <ul className={s.navItems}>
          <li
            className={cx(s.navItem, {
              [s.active]: currentNavItem === "ABOUT",
              [s.dark]: isDarkTheme,
            })}
            onMouseEnter={() => setCurrentNavItem("ABOUT")}
          >
            <p className={cx(s.text, { [s.dark]: isDarkTheme })}>{t("header.link.about.title")}</p>
            <div className={`${s.arrow} ${s.down}`}>
              <Arrow color={isDarkTheme ? "#727272" : "#ffffff"} />
            </div>
          </li>

          <li
            className={cx(s.navItem, {
              [s.active]: currentNavItem === "FINANCIAL_INFO",
              [s.dark]: isDarkTheme,
            })}
            onMouseEnter={() => setCurrentNavItem("FINANCIAL_INFO")}
          >
            <p className={cx(s.text, { [s.dark]: isDarkTheme })}>{t("header.link.financialInfo.title")}</p>
            <div className={`${s.arrow} ${s.down}`}>
              <Arrow color={isDarkTheme ? "#727272" : "#ffffff"} />
            </div>
          </li>

          <Link style={{ textDecoration: "none" }} to={t("routes.contact")}>
            <li
              className={cx(s.navItem, {
                [s.dark]: isDarkTheme,
              })}
              onMouseEnter={() => setCurrentNavItem(null)}
            >
              <p className={cx(s.text, { [s.dark]: isDarkTheme })}>{t("header.link.contact")}</p>
            </li>
          </Link>

          {/* SUBMENU */}
          <ul className={`${s.submenu} submenu`} ref={submenuRef}>
            {submenuItems &&
              submenuItems.map((item, i) => {
                if (item.url) {
                  return (
                    <Link
                      className={cx(s.link, {
                        [s.inProgress]: item.inProgress,
                        [s.dark]: isDarkTheme,
                      })}
                      to={item.url}
                      key={i}
                      onMouseEnter={scaleCursorUp}
                      onMouseLeave={scaleCursorDown}
                    >
                      <li className={`${s.submenuItem} submenu-item`}>
                        <div className={`${s.arrow} ${s.right}`}>
                          <Arrow color={isDarkTheme ? "#003566" : "#ffffff"} />
                        </div>
                        <p className={s.text}>{item.title}</p>
                      </li>
                    </Link>
                  )
                } else if (item.pdf) {
                  return (
                    <a
                      className={cx(s.link, {
                        [s.inProgress]: item.inProgress,
                        [s.dark]: isDarkTheme,
                      })}
                      target="_blank"
                      rel="noreferrer"
                      href={item.pdf}
                      key={i}
                      onMouseEnter={scaleCursorUp}
                      onMouseLeave={scaleCursorDown}
                    >
                      <li className={`${s.submenuItem} submenu-item`}>
                        <div className={`${s.arrow} ${s.right}`}>
                          <Arrow color={isDarkTheme ? "#003566" : "#ffffff"} />
                        </div>
                        <p className={s.text}>{item.title}</p>
                      </li>
                    </a>
                  )
                } else if (item.sub?.length) {
                  return (
                    <div className={s.wrapper} onMouseLeave={handleSubmenuClose}>
                      <a
                        className={cx(s.link, {
                          [s.inProgress]: item.inProgress,
                          [s.dark]: isDarkTheme,
                        })}
                        target="_blank"
                        rel="noreferrer"
                        href={item.pdf}
                        key={i}
                        onMouseEnter={() => handleSubmenuOpen(item)}
                      >
                        <li className={`${s.submenuItem} submenu-item`}>
                          <div className={`${s.arrow} ${s.right}`}>
                            <Arrow color={isDarkTheme ? "#003566" : "#ffffff"} />
                          </div>
                          <p className={s.text}>{item.title}</p>
                        </li>
                      </a>

                      <div
                        className={s.innerItems}
                        style={{
                          maxHeight: `${item.type === currentSubmenu ? "200px" : "0px"}`,
                          opacity: `${item.type === currentSubmenu ? "1" : "0"}`,
                          transition: "all 600ms ease",
                        }}
                      >
                        {item.sub?.length &&
                          item.sub.map((item, i) => {
                            return (
                              <a
                                className={cx(s.link, {
                                  [s.inProgress]: item.inProgress,
                                  [s.dark]: isDarkTheme,
                                })}
                                target="_blank"
                                rel="noreferrer"
                                href={item.pdf}
                                key={i}
                                // onMouseEnter={scaleCursorUp}
                                // onMouseLeave={scaleCursorDown}
                              >
                                <li className={`${s.submenuItemInner} submenu-item`}>
                                  <p className={s.text}>{item.title}</p>
                                </li>
                              </a>
                            )
                          })}
                      </div>
                    </div>
                  )
                }
              })}

            {submenuItems && (
              <div
                className={cx(s.btnWrapper, { [s.dark]: isDarkTheme })}
                onMouseEnter={scaleCursorUp}
                onMouseLeave={scaleCursorDown}
              >
                <Link to={t("routes.contact")}>
                  <AnimatedButton
                    text={t("header.contactUsBtnText")}
                    color={isDarkTheme ? "#003566" : "#ffffff"}
                    iconSrc={arrow}
                  />
                </Link>
              </div>
            )}
          </ul>
        </ul>
      </nav>

      <nav className={cx(s.navMenu, "nav-menu", { [s.dark]: isDarkTheme })}>
        <div
          onMouseEnter={() => setCurrentNavItem(null)}
          className={s.closeOverlay}
          style={{
            width: closeOverlayWidth,
          }}
        ></div>
      </nav>
    </header>
  )
}
