import create from "zustand";

const useCursorStore = create((set) => ({
  scaled: false,
  hidden: false,
  hide: () => set((state) => ({ hidden: !state.hidden })),
  scaleUp: () => set(() => ({ scaled: true })),
  scaleDown: () => set(() => ({ scaled: false })),
}));

export default useCursorStore;
