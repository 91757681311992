import React, { useEffect, useRef, useState } from "react"
import s from "../assets/scss/home.module.scss"

import bosphorusTouch from "../assets/img/bosphorus-touch.jpg"
import bosphorus from "../assets/img/bosphorus.jpg"

import arrowGray from "../assets/icon/arrow-gray.svg"
import sphere from "../assets/img/sphere.png"

import { AnimatePresence, motion } from "framer-motion"
import { useTranslation } from "react-i18next"
import { useResizeDetector } from "react-resize-detector"
import AnimatedButton from "../components/animatedButton"

import classNames from "classnames"

import { Expo } from "gsap"
import { Link, useNavigate } from "react-router-dom"
import useWindowSize from "../hooks/useWindowSize"
import usePreloaderStore from "../stores/preloaderStore"
import useHeaderStore from "../stores/themeStore"
import { ScrollTrigger, gsap } from "../utils/gsap"

import gsyfInternalRegulation1 from "../assets/pdf/birinci-gsyf-ic-tuzuk.pdf"
import gsyfInternalRegulation2 from "../assets/pdf/ikinci-gsyf-guncel-ic-tuzuk.pdf"
import gyfInternalRegulation1 from "../assets/pdf/birinci-gyf-guncel-ic-tuzuk.pdf"
import gyfInternalRegulation2 from "../assets/pdf/ikinci-gyf-guncel-ic-tuzuk.pdf"
import gsyfExportDocument1 from "../assets/pdf/birinci-gsyf-guncel-ihrac-belgesi.pdf"
import gsyfExportDocument2 from "../assets/pdf/ikinci-gsyf-guncel-ihrac-belgesi.pdf"
import gyfExportDocument1 from "../assets/pdf/birinci-gyf-guncel-ihrac-belgesi.pdf"
import gyfExportDocument2 from "../assets/pdf/ikinci-gyf-guncel-ihrac-belgesi.pdf"

import entrepreneurship1 from "../assets/pdf/wam-birinci-girisim-sermayesi-yatirim-fonu-ictuzugu.pdf"
import entrepreneurship2 from "../assets/pdf/wam-ikinci-girisim-sermayesi-yatirim-fonu-ictuzugu.pdf"

export default function Home() {
  const homeWrapperRef = useRef(null)
  const q = gsap.utils.selector(homeWrapperRef)

  const [activeAccordions, setActiveAccordions] = useState([])

  const aboutUsRef = useRef(null)
  const heroRef = useRef(null)
  const scalingRef = useRef(null)
  const scalingWrapperRef = useRef(null)
  const sphereRef = useRef(null)

  const [textExpanded, setTextExpanded] = useState(false)

  const isAnimationEnd = usePreloaderStore((state) => state.animationEnd)

  const { t, i18n } = useTranslation()
  const size = useWindowSize()

  const onResize = () => {
    ScrollTrigger.refresh()
  }

  useResizeDetector({ targetRef: homeWrapperRef, onResize })

  const setHeaderLight = useHeaderStore((state) => state.setLightTheme)

  useEffect(() => {
    setHeaderLight()
  }, [])

  let navigate = useNavigate()
  useEffect(() => {
    if (i18n.language === "TR") {
      navigate("/anasayfa")
    } else {
      navigate("/home")
    }
  }, [i18n.language])

  // INIT
  useEffect(() => {
    q("[data-fadein]").forEach((item) => {
      gsap.set(item, {
        autoAlpha: 0,
        willChange: "transform",
        yPercent: () => item.dataset.yPercent,
      })
    })

    q("[data-parallax]").forEach((item) => {
      gsap.to(item, {
        yPercent: () => item.dataset.yPercent,
        scrollTrigger: {
          trigger: item,
          start: "center center+=15%",
          scrub: 1,
          // markers: true,
        },
      })
    })

    q("[data-fadein-oninit]").forEach((item) => {
      gsap.set(item, {
        autoAlpha: 0,
        willChange: "transform",
        yPercent: () => item.dataset.yPercent,
      })
    })

    gsap.fromTo(
      scalingRef.current,
      {
        transformOrigin: "100% 100%",
        attr: { d: "M0 20 Q 50 45 100 20 L100 60 L0 60 Z" },
        scaleY: 0.1,
      },
      {
        attr: { d: "M0 20 Q 50 0 100 20 L100 60 L0 60 Z" },
        scaleY: 0.2,
        scrollTrigger: {
          trigger: scalingRef.current,
          // start: "top bottom-=40%",
          // end: "bottom-=10% top",
          // markers: { startColor: "blue", endColor: "purple" },
          scrub: 1,
        },
      }
    )
  }, [])

  useEffect(() => {
    q("[data-fadein]").forEach((item) => {
      gsap.to(item, {
        autoAlpha: 1,
        yPercent: 0,
        duration: 2.4,
        ease: Expo.easeOut,
        scrollTrigger: {
          trigger: item,
          start: () => (size > 1024 ? "center center+=25%" : "top bottom"),
          toggleActions: "play none none none",
          // markers: true,
        },
      })
    })
  }, [size, q])

  useEffect(() => {
    if (isAnimationEnd) {
      q("[data-fadein-oninit]").forEach((item) => {
        gsap.to(item, {
          autoAlpha: 1,
          yPercent: 0,
          duration: 2,
          ease: Expo.easeOut,
        })
      })
    }
  }, [isAnimationEnd, q])

  function handleAccordion(index) {
    if (activeAccordions.includes(index)) {
      const filtered = activeAccordions.filter((item) => {
        return index !== item
      })
      return setActiveAccordions(filtered)
    }
    return setActiveAccordions((prev) => [...prev, index])
  }

  return (
    <main className={s.homeWrapper} ref={homeWrapperRef}>
      <section className={`${s.hero} hero`} ref={heroRef}>
        <div className={s.imgWrapper}>
          <img
            className={`${s.heroImg} hero-img`}
            src={window.innerWidth > 1024 ? bosphorus : bosphorusTouch}
            alt="Bosphorus by Sunset"
          />
        </div>
        <h1 className={`${s.title} title`} data-fadein-oninit data-y-percent="50">
          {t("home.hero.title.part1")} <br className={s.mobile} /> {t("home.hero.title.part2")} <br />{" "}
          <strong>{t("home.hero.title.part3")}</strong>
          <br className={s.tablet} /> {t("home.hero.title.part4")} <br className={s.tablet} />
          {t("home.hero.title.part5")}
        </h1>
        <div className={s.heroText} data-fadein-oninit data-y-percent="50">
          <p>{t("home.hero.text.part1")}</p>
          <p>{t("home.hero.text.part2")}</p>
        </div>
        <div ref={scalingWrapperRef} className={s.scalingWrapper}>
          <svg viewBox="0 0 100 60" xmlns="http://www.w3.org/2000/svg" className={s.scaling}>
            <path ref={scalingRef} d="M0 20 Q 50 40 100 20 L100 60 L0 60 Z" fill="#fdfdfd" />
          </svg>
        </div>
      </section>

      <section className={s.aboutUs} ref={aboutUsRef}>
        <div className={s.heroTextTouchScreen}>
          <div className={s.textWrapper}>
            <p data-fadein data-y-percent="50">
              {t("home.hero.text.part1")}
            </p>
            <p data-fadein data-y-percent="100">
              {t("home.hero.text.part2")}
            </p>
          </div>
        </div>
        <img className={s.sphere} src={sphere} alt="Sphere" ref={sphereRef} data-parallax data-y-percent="-20" />
        <div className={s.desktop}>
          <h1 className={s.title}>{t("home.aboutUs.title")}</h1>
          <div className={s.texts}>
            <div className={s.textBox} data-fadein data-y-percent="50">
              <h3>
                {t("home.aboutUs.ourValues.title.part1")} & <br /> {t("home.aboutUs.ourValues.title.part2")}
              </h3>
              <p className={`${s.text} ${s.lined}`}>{t("home.aboutUs.ourValues.text.part1")}</p>
              {size.width <= 640 ? (
                <>
                  <div
                    className={s.oWrapper}
                    style={{
                      transition: "0.8s max-height ease-in-out",
                      maxHeight: textExpanded ? "400px" : "0",
                    }}
                  >
                    <p className={`${s.text} expandable`}>{t("home.aboutUs.ourValues.text.part2")}</p>
                  </div>
                  <div className={s.btnWrapper} onClick={() => setTextExpanded((prev) => !prev)}>
                    <button className={s.expandBtn}>
                      <AnimatePresence exitBeforeEnter>
                        <motion.p
                          className={s.btnText}
                          key={textExpanded}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          {textExpanded
                            ? t("home.aboutUs.mobileExpandBtnText.shrink")
                            : t("home.aboutUs.mobileExpandBtnText.expand")}
                        </motion.p>
                      </AnimatePresence>
                      <img
                        style={{
                          transition: "0.4s transform ease",
                          transform: textExpanded ? "rotate(-90deg)" : "rotate(90deg)",
                        }}
                        src={arrowGray}
                        alt="Arrow"
                      />
                    </button>
                  </div>
                </>
              ) : (
                <p className={`${s.text} expandable`}>{t("home.aboutUs.ourValues.text.part2")}</p>
              )}
            </div>
          </div>
          <div className={`${s.texts} ${s.right}`}>
            <div className={s.textBox} data-fadein data-y-percent="30">
              <h3>{t("home.aboutUs.ourTeam.title")}</h3>
              <p className={`${s.text} ${s.lined}`}>{t("home.aboutUs.ourTeam.text")}</p>

              <div className={s.btnWrapper}>
                <Link to={t("routes.boardMembers")} style={{ marginBottom: "2rem" }}>
                  <AnimatedButton
                    color="#727272"
                    iconSrc={arrowGray}
                    text={t("home.aboutUs.ourTeam.btnText1")}
                  ></AnimatedButton>
                </Link>
              </div>
              <div className={s.btnWrapper}>
                <Link to={t("routes.executives")}>
                  <AnimatedButton
                    color="#727272"
                    iconSrc={arrowGray}
                    text={t("home.aboutUs.ourTeam.btnText2")}
                  ></AnimatedButton>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={s.ourFunds}>
        <div className={s.video}>
          <div className={s.mediaWrapper}>
            <div className={s.inner}>
              <iframe
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  zIndex: "0",
                }}
                title="Bosphorus by night"
                // src="https://player.vimeo.com/video/747277790?background=1=1&amp;h=d7240068b8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                src="https://player.vimeo.com/video/747277790?background=1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                allowFullScreen
                autoPlay
              ></iframe>
            </div>
          </div>
          <h1 className={s.title} data-fadein data-y-percent="20">
            {t("home.ourFunds.title")}
          </h1>
        </div>
        <h1 className={s.titleTouchScreen} data-fadein data-y-percent="100">
          {t("home.ourFunds.title")}
        </h1>
        <div className={s.textWrapper}>
          <div className={s.textBox} data-fadein data-y-percent="20">
            <h3>
              {t("home.ourFunds.propertyFunds.title.part1")} <br /> {t("home.ourFunds.propertyFunds.title.part2")}
            </h3>

            <div className={s.btnWrapper} onClick={() => handleAccordion(0)}>
              <div className={s.link} style={{ textDecoration: "none" }}>
                <AnimatedButton
                  color="#727272"
                  iconSrc={arrowGray}
                  text={t("home.ourFunds.propertyFunds.btns.b1.ui")}
                  dims="sm"
                ></AnimatedButton>
              </div>
            </div>

            <div className={classNames(s.accordionItems, { [s.active]: activeAccordions.includes(0) })}>
              <div className={s.inner}>
                <div className={s.btnWrapper}>
                  <a
                    className={s.link}
                    href={gyfInternalRegulation1}
                    target="_blank"
                    rel="noreferrer noopoener"
                    style={{ marginBottom: "2rem", textDecoration: "none" }}
                  >
                    <AnimatedButton
                      color="#727272"
                      iconSrc={arrowGray}
                      text={t("home.ourFunds.propertyFunds.accordionItems.b1.ui")}
                      dims="sm"
                    ></AnimatedButton>
                  </a>
                </div>
                <div className={s.btnWrapper}>
                  <a
                    className={s.link}
                    href={gyfExportDocument1}
                    target="_blank"
                    rel="noreferrer noopoener"
                    style={{ textDecoration: "none" }}
                  >
                    <AnimatedButton
                      color="#727272"
                      iconSrc={arrowGray}
                      text={t("home.ourFunds.propertyFunds.accordionItems.b2.ui")}
                      dims="sm"
                    ></AnimatedButton>
                  </a>
                </div>
              </div>
            </div>

            <div className={s.btnWrapper} onClick={() => handleAccordion(1)}>
              <div className={s.link} style={{ textDecoration: "none" }}>
                <AnimatedButton
                  color="#727272"
                  iconSrc={arrowGray}
                  text={t("home.ourFunds.propertyFunds.btns.b2.ui")}
                  dims="sm"
                ></AnimatedButton>
              </div>
            </div>

            <div className={classNames(s.accordionItems, { [s.active]: activeAccordions.includes(1) })}>
              <div className={s.inner}>
                <div className={s.btnWrapper}>
                  <a
                    className={s.link}
                    href={gyfInternalRegulation2}
                    target="_blank"
                    rel="noreferrer noopoener"
                    style={{ marginBottom: "2rem", textDecoration: "none" }}
                  >
                    <AnimatedButton
                      color="#727272"
                      iconSrc={arrowGray}
                      text={t("home.ourFunds.propertyFunds.accordionItems.b3.ui")}
                      dims="sm"
                    ></AnimatedButton>
                  </a>
                </div>
                <div className={s.btnWrapper}>
                  <a
                    className={s.link}
                    href={gyfExportDocument2}
                    target="_blank"
                    rel="noreferrer noopoener"
                    style={{ marginBottom: "2rem", textDecoration: "none" }}
                  >
                    <AnimatedButton
                      color="#727272"
                      iconSrc={arrowGray}
                      text={t("home.ourFunds.propertyFunds.accordionItems.b4.ui")}
                      dims="sm"
                    ></AnimatedButton>
                  </a>
                </div>
              </div>
            </div>

            {/* <p className={`${s.text} ${s.lined}`}>{t("home.ourFunds.propertyFunds.text")}</p> */}
          </div>

          <div className={s.textBox} data-fadein data-y-percent="40">
            <h3>
              {t("home.ourFunds.investmentFunds.title.part1")} <br /> {t("home.ourFunds.investmentFunds.title.part2")}
            </h3>

            <div className={s.btnWrapper} onClick={() => handleAccordion(2)}>
              <div className={s.link} style={{ textDecoration: "none" }}>
                <AnimatedButton
                  color="#727272"
                  iconSrc={arrowGray}
                  text={t("home.ourFunds.investmentFunds.btns.b1.ui")}
                  dims="sm"
                ></AnimatedButton>
              </div>
            </div>

            <div className={classNames(s.accordionItems, { [s.active]: activeAccordions.includes(2) })}>
              <div className={s.inner}>
                <div className={s.btnWrapper}>
                  <a
                    className={s.link}
                    href={gsyfInternalRegulation1}
                    target="_blank"
                    rel="noreferrer noopoener"
                    style={{ marginBottom: "2rem", textDecoration: "none" }}
                  >
                    <AnimatedButton
                      color="#727272"
                      iconSrc={arrowGray}
                      text={t("home.ourFunds.investmentFunds.accordionItems.b1.ui")}
                      dims="sm"
                    ></AnimatedButton>
                  </a>
                </div>
                <div className={s.btnWrapper}>
                  <a
                    className={s.link}
                    href={gsyfExportDocument1}
                    target="_blank"
                    rel="noreferrer noopoener"
                    style={{ textDecoration: "none" }}
                  >
                    <AnimatedButton
                      color="#727272"
                      iconSrc={arrowGray}
                      text={t("home.ourFunds.investmentFunds.accordionItems.b2.ui")}
                      dims="sm"
                    ></AnimatedButton>
                  </a>
                </div>
              </div>
            </div>

            <div className={s.btnWrapper} onClick={() => handleAccordion(3)}>
              <div className={s.link} style={{ textDecoration: "none" }}>
                <AnimatedButton
                  color="#727272"
                  iconSrc={arrowGray}
                  text={t("home.ourFunds.investmentFunds.btns.b2.ui")}
                  dims="sm"
                ></AnimatedButton>
              </div>
            </div>

            <div className={classNames(s.accordionItems, { [s.active]: activeAccordions.includes(3) })}>
              <div className={s.inner}>
                <div className={s.btnWrapper}>
                  <a
                    className={s.link}
                    href={gsyfInternalRegulation2}
                    target="_blank"
                    rel="noreferrer noopoener"
                    style={{ marginBottom: "2rem", textDecoration: "none" }}
                  >
                    <AnimatedButton
                      color="#727272"
                      iconSrc={arrowGray}
                      text={t("home.ourFunds.investmentFunds.accordionItems.b3.ui")}
                      dims="sm"
                    ></AnimatedButton>
                  </a>
                </div>
                <div className={s.btnWrapper}>
                  <a
                    className={s.link}
                    href={gsyfExportDocument2}
                    target="_blank"
                    rel="noreferrer noopoener"
                    style={{ marginBottom: "2rem", textDecoration: "none" }}
                  >
                    <AnimatedButton
                      color="#727272"
                      iconSrc={arrowGray}
                      text={t("home.ourFunds.investmentFunds.accordionItems.b4.ui")}
                      dims="sm"
                    ></AnimatedButton>
                  </a>
                </div>
              </div>
            </div>

            {/* <p className={`${s.text} ${s.lined}`}>{t("home.ourFunds.investmentFunds.text")}</p> */}
          </div>
        </div>
        <img className={s.sphere} src={sphere} alt="Sphere" />
      </section>
    </main>
  )
}
