import React from "react";
import s from "../assets/scss/languageDropdown.module.scss";

import i18n from "../i18n/i18n";
import useHeaderStore from "../stores/themeStore";
import Arrow from "./iconArrow";

import cx from "classnames";

const lngs = {
  en: { nativeName: "EN" },
  tr: { nativeName: "TR" },
};

export default function LanguageDropdown({ color = "#000000", lg = false }) {
  const isDarkTheme = useHeaderStore((state) => state.isDark);

  const handleLang = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <div className={cx(s.selectWrapper, { [s.lg]: lg })}>
      <select
        name="language"
        className={s.langSelect}
        onChange={handleLang}
        value={i18n.language}
        style={{ color: isDarkTheme ? "#727272" : color }}
      >
        {Object.keys(lngs).map((lng) => (
          <option
            key={lng}
            style={{
              fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
            }}
            value={lngs[lng].nativeName}
          >
            {lngs[lng].nativeName}
          </option>
        ))}
      </select>
      <div className={s.arrow}>
        <Arrow color={isDarkTheme ? "#727272" : color}></Arrow>
      </div>
    </div>
  );
}
