import React, { useEffect, useRef } from "react";
import s from "../assets/scss/executivePanel.module.scss";

import canIkinci from "../assets/img/can-ikinci.jpg";
import turkerTekten from "../assets/img/turker-tekten.jpg";

import close from "../assets/icon/close.svg";
import useExecPanelStore from "../stores/executivePanelStore";
import gsap, { Power4 } from "gsap";
import { useTranslation } from "react-i18next";

export default function ExecutivePanel({ currentExec = null }) {
  const panelRef = useRef(null);
  const q = gsap.utils.selector(panelRef);

  const panelTL = useRef(null);
  const { t } = useTranslation();

  const togglePanel = useExecPanelStore((state) => state.toggle);

  const items = [
    {
      name: t("executiveInfo.e1.name"),
      role: t("executiveInfo.e1.role"),
      imgSrc: canIkinci,
      detailedInfo: [
        t("executiveInfo.e1.detail.part1"),
        t("executiveInfo.e1.detail.part2"),
        t("executiveInfo.e1.detail.part3"),
      ],
    },
    {
      name: t("executiveInfo.e2.name"),
      role: t("executiveInfo.e2.role"),
      imgSrc: turkerTekten,
      detailedInfo: [
        t("executiveInfo.e2.detail.part1"),
        t("executiveInfo.e2.detail.part2"),
        t("executiveInfo.e2.detail.part3"),
        t("executiveInfo.e2.detail.part4"),
      ],
    },
  ];

  useEffect(() => {
    gsap.set(
      [
        panelRef.current,
        ...q("[data-exec-img-wrapper]"),
        ...q("[data-exec-img]"),
        ...q("[data-exec-title]"),
        ...q("[data-exec-role]"),
        ...q("[data-exec-text]"),
        ...q("[data-exec-close]"),
        ...q("[data-exec-progress]"),
      ],
      {
        xPercent: 100,
        autoAlpha: 0,
      }
    );

    gsap.set(q("[data-exec-bar]"), {
      transformOrigin: "0 0",
      scaleY: 0.2,
    });

    gsap.to(q("[data-exec-bar]"), {
      scaleY: 1,
      scrollTrigger: {
        scroller: q("[data-exec-text]"),
        scrub: 1,
        // markers: true,
      },
    });

    panelTL.current = gsap
      .timeline({ paused: true })
      .add("s")
      .to(
        [
          panelRef.current,
          ...q("[data-exec-img-wrapper]"),
          ...q("[data-exec-img]"),
          ...q("[data-exec-title]"),
          ...q("[data-exec-role]"),
          ...q("[data-exec-text]"),
          ...q("[data-exec-close]"),
          ...q("[data-exec-progress]"),
        ],
        {
          xPercent: 0,
          autoAlpha: 1,
          duration: 1.5,
          ease: Power4.easeInOut,
          stagger: 0.02,
        },
        "s"
      );
  }, []);

  const isPanelOpen = useExecPanelStore((state) => state.isOpen);

  useEffect(() => {
    if (isPanelOpen) {
      document.body.style.overflow = "hidden";
      panelTL.current.play();
    } else {
      document.body.style.overflow = "auto";
      panelTL.current.reverse();
    }
  }, [isPanelOpen]);

  return (
    <div className={s.executivePanel} ref={panelRef}>
      <div className={s.close} onClick={togglePanel} data-exec-close>
        <img className={s.icon} src={close} alt="Close Icon" />
      </div>
      <div className={s.imgWrapper}>
        <img
          className={s.img}
          src={items[currentExec].imgSrc}
          alt="Executive"
          data-exec-img
        />
      </div>
      <div className={s.details}>
        <div className={s.titleWrapper}>
          <h5 className={s.title} data-exec-title>
            {items[currentExec].name}
          </h5>
          <small className={s.role} data-exec-role>
            {items[currentExec].role}
          </small>
        </div>
        <div className={s.scrollWrapper}>
          <div className={s.progress} data-exec-progress>
            <div className={s.bar} data-exec-bar></div>
          </div>
          <div className={s.text} data-exec-text>
            {items[currentExec].detailedInfo.map((info, i) => {
              return (
                <p className={s.par} key={i}>
                  {info}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
