import create from "zustand";

const useExecPanelStore = create((set) => ({
  isOpen: false,
  currentExec: 0,
  toggle: () => set((state) => ({ isOpen: !state.isOpen })),
  setCurrentExec: (data) => set((state) => ({ currentExec: data })),
}));

export default useExecPanelStore;
