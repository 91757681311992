import React, { useState } from "react";
import { useRef } from "react";
import s from "../assets/scss/preloader.module.scss";

import logo from "../assets/img/wam-logo.svg";
import gsap, { Expo } from "gsap";
import { useEffect } from "react";
import usePreloaderStore from "../stores/preloaderStore";

export default function Preloader() {
  const preloaderRef = useRef(null);
  const q = gsap.utils.selector(preloaderRef);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // document.body.style.overflow = "hidden";
    disableScrolling();

    gsap.set(q("[data-preloader-bar]"), {
      scaleX: 0,
      duration: 0,
    });
  }, []);

  useEffect(() => {
    gsap.to(q("[data-preloader-bar]"), {
      scaleX: () => gsap.utils.mapRange(0, 100, 0, 1, progress),
      duration: 1.5,
      ease: Expo.in,
    });
  }, [progress]);

  useEffect(() => {
    let img, c, tot;

    img = document.images;
    c = 0;
    tot = img.length;

    const imgLoaded = () => {
      c += 1;
      setProgress(((100 / tot) * c) << 0);

      if (c === tot) return up();
      if (c === tot) return;
    };

    const counter = () => {
      for (var i = 0; i < tot; i++) {
        var tImg = new Image();
        tImg.onload = imgLoaded;
        tImg.onerror = imgLoaded;
        tImg.src = img[i].src;
      }
    };

    counter();
  }, []);

  const animationEnd = usePreloaderStore((state) => state.end);

  const up = () => {
    gsap.to(preloaderRef.current, {
      autoAlpha: 0,
      duration: 1.2,
      delay: 2,
      onStart: () => {
        // document.body.style.overflow = "auto";
        enableScrolling();
        animationEnd();
        gsap.to([...q("[data-preloader-logo]"), ...q("[data-preloader-bar]")], {
          autoAlpha: 0,
          duration: 0.6,
        });
      },
    });
  };

  function disableScrolling() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }

  function enableScrolling() {
    window.onscroll = function () {};
  }

  return (
    <div
      className={s.preloader}
      ref={preloaderRef}
      style={{ width: window.innerWidth, height: window.innerHeight }}
    >
      <img className={s.logo} data-preloader-logo src={logo} alt="WAM Logo" />
      <div className={s.bar} data-preloader-bar></div>
    </div>
  );
}
