import React from "react";

export default function IconHamburger({ fill = "none", stroke = "#ffffff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 36.727 17.485"
    >
      <g id="Group_113" data-name="Group 113" transform="translate(0 0.5)">
        <path
          id="Path_4671"
          data-name="Path 4671"
          d="M0,0H36.727"
          fill={fill}
          stroke={stroke}
          strokeWidth="2"
        />
        <path
          id="Path_4672"
          data-name="Path 4672"
          d="M0,0H36.727"
          transform="translate(0 16.485)"
          fill={fill}
          stroke={stroke}
          strokeWidth="2"
        />
        <path
          id="Path_4670"
          data-name="Path 4670"
          d="M0,0H36.727"
          transform="translate(0 8.242)"
          fill={fill}
          stroke={stroke}
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
