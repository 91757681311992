import React from "react";

export default function Arrow({ color = "#ffffff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8.006 11.032"
      width="100%"
      height="100%"
    >
      <path
        id="Path_4658"
        data-name="Path 4658"
        d="M2.122,5.516,0,11.032,8.006,5.516,0,0Z"
        transform="translate(0 0)"
        fill={color}
      />
    </svg>
  );
}
