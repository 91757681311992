import React, { useEffect, useRef } from "react"
import s from "../assets/scss/animatedButton.module.scss"

import cx from "classnames"

import arrow from "../assets/icon/arrow-gray.svg"
import gsap, { Power1 } from "gsap"

export default function AnimatedButton({
  text = "Animated Button",
  color = "#727272",
  iconSrc = arrow,
  rotate = "0",
  dims = "",
}) {
  const overflowWrapperRef = useRef(null)
  const q = gsap.utils.selector(overflowWrapperRef)

  const btnTL = useRef(null)

  useEffect(() => {
    btnTL.current = gsap.timeline({ paused: true }).to(q(".btn-wrapper"), {
      x: 0,
      duration: 0.5,
      ease: Power1.easeInOut,
    })
  }, [q])

  useEffect(() => {
    // const btnWrapper = q(".btn-wrapper");
    // const btnText = q(".btn-text");
    // const lArrow = q(".left-arrow");
    // const rArrow = q(".right-arrow");

    gsap.set(overflowWrapperRef.current, {
      width: () => q(".right-arrow")[0].getBoundingClientRect().width + q(".btn-text")[0].getBoundingClientRect().width,
    })

    gsap.set(q(".btn-wrapper"), {
      x: () => -q(".left-arrow")[0].getBoundingClientRect().width,
    })

    // console.log(
    //   "overflow",
    //   overflowWrapperRef.current.getBoundingClientRect().width
    // );
    // console.log("btn", btn[0].getBoundingClientRect().width);
    // console.log("btnText", btnText[0].getBoundingClientRect().width);
    // console.log("lArrow", lArrow[0].getBoundingClientRect().width);
    // console.log("rArrow", rArrow[0].getBoundingClientRect().width);
  }, [q])

  const animate = () => {
    btnTL.current.play()
  }

  const animateR = () => {
    btnTL.current.reverse()
  }

  return (
    <div
      className={cx(s.overflowWrapper, [s[dims]])}
      ref={overflowWrapperRef}
      onMouseEnter={animate}
      onMouseLeave={animateR}
    >
      <div className={`${s.btnWrapper} btn-wrapper`}>
        <div className={`${s.iconWrapper} ${s.left} left-arrow`}>
          <img className={`${s.arrow} ${s.left} left-arrow`} src={iconSrc} alt="Arrow" />
        </div>

        <p style={{ color }} className={`${s.btnText} btn-text`}>
          {text}
        </p>
        <div className={`${s.iconWrapper} ${s.right} right-arrow`}>
          <img className={s.arrow} src={iconSrc} alt="Arrow" style={{ transform: `rotate(${rotate}deg)` }} />
        </div>
      </div>
      <span style={{ background: color }} className={s.underlineL}></span>
      <span style={{ background: color }} className={s.underlineR}></span>
    </div>
  )
}
