import axios from "axios"

export async function getLang(ipAddress) {
  return await axios({
    method: "get",
    url: "http://language.wamturkey.com/langService.php",
    params: {
      ip: ipAddress,
    },
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}
